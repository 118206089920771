$ = jQuery;
$( document ).ready(function() {
    // Manage the prime rate slider
    if ($("#sliderValue").length > 0) {
        const slider = document.getElementById('primeRate');
        const sliderValue = document.getElementById('sliderValue');
        const printSliderValue = document.getElementById('print-override-rate');
        const markerContainer = document.querySelector('.marker-container');
        
        slider.addEventListener('input', function() {
            sliderValue.textContent = slider.value + '%';
            printSliderValue.textContent = slider.value + '%';
        });
        
        function createMarkers(min, max) {
            for (let i = min; i <= max; i++) {
                const marker = document.createElement('div');
                marker.className = 'marker';
                markerContainer.appendChild(marker);
            }
        }
        createMarkers(0, 20);
    }

    if ($("#operatingLoanProductDiscount").length == 0 && ($("#showDiscounts").length == 0 || $("#showDiscounts").is(":checked") == false)) {
        $("tr.discount-row").hide();
    }
    
    // show and hide discounts
    if ($("#showDiscounts").length) {
        let toggleProductDiscount = function() {
            if($(this).is(":checked")) {
                $('#discountValues, .product-discount-results').show();
                $("tr.discount-row").show();
            } else {
                $('#discountValues, .product-discount-results').hide();
                $('#productDiscount, #cashDiscount').val(0);
                $("tr.discount-row").hide();
            }
        };
        $("#showDiscounts").change(toggleProductDiscount);
        toggleProductDiscount();
    }

    if ($("#showPrimeRateSlider").length) {
        let togglePrimeRateSlider = function() {
            if($(this).is(":checked")) {
                $('#prime-rate-slider-container').show();
            } else {
                $('#prime-rate-slider-container').hide();
                let defaultPrimeRate = $("#primeRateDefault").val();
                $("#primeRate").val(defaultPrimeRate)
                $("#sliderValue").html(defaultPrimeRate);
            }
        };
        $("#showPrimeRateSlider").change(togglePrimeRateSlider);
        togglePrimeRateSlider();
    }
    
    var statementCycleDays = 30;
    
    $('#calculate').click(function(e){
        e.preventDefault();
        e.stopPropagation();
        calculate();
    });
    if($('#paymentDueDateMonthYear').length > 0) {
        updatePaymentDueDateMonthYear();
    }
    $('#paymentDueDateMonthYear').change(function (event) {
        updatePaymentDueDateMonthYear();
    });
    
    $('#paymentDueDateMonth').change(function (event) {
        if ($(this).val() == 2) {
            statementCycleDays = 28;
        } else {
            statementCycleDays = 30;
        }
        
        console.info('Statement cycle days: ' + statementCycleDays);
        
        if (validatePaymentDate()) {
            //calculate();
        } else {
            // Invalid payment (month) date.
        }
    });
    
    /**
    * Update the Due Date Month and Year input fields
    */
    function updatePaymentDueDateMonthYear() {
        // get the value from the Due Date dropdown
        var value = $('#paymentDueDateMonthYear').val(),
        month = value.substr(0,2);
        // update the Due Date Month input field
        $('#paymentDueDateMonth').val(month);
        // update the Due Date Year input field
        $('#paymentDueDateYear').val(value.substr(3,4));
        // update the Statement Cycle Days
        if (month === '02') {
            statementCycleDays = 28;
        } else {
            statementCycleDays = 30;
        }
    }
    
    function calculate() {
        function processDiscounts (operatingLoanProductDiscount = false, programExampleProductDiscount = false) {
            if (operatingLoanProductDiscount != false && parseInt(operatingLoanProductDiscount) != 0) {
                // Manage Operating Loan Product Discount
                discountLabel = operatingLoanProductDiscount + '%';
                discountValue = operatingLoanProductDiscount;
                console.log('Operating Loan Product Discount = ' + discountLabel);
                    
                
                operatingLoanDateDiscount = parseFloat(discountValue / 100) * principal;
                $('#operatingLoanDateDiscountAmount').text('$' + accounting.formatNumber(operatingLoanDateDiscount));
                $('#operatingLoanDateDiscountLabel').text(discountLabel);
                
                // Set the operating loan program seed discounted amount.
                operatingLoanDateDiscountedAmount = parseFloat(principal - operatingLoanDateDiscount);
                $('#operatingLoanDateDiscountedAmount').text('$' + accounting.formatNumber(operatingLoanDateDiscountedAmount));
                
                // Get the adjusted operating loan principal.
                adjustedOperatingLoanPrincipal = getDifference(principal, operatingLoanDateDiscount);
                
                // Set the adjusted operating loan interest charge.
                operatingLoanTotalInterest = Math.round((adjustedOperatingLoanPrincipal) * ((operatingLoanInterestRate / 365) * operatingLoanDaysOfInterest));
                console.log('operatingLoanTotalInterest: ' + operatingLoanTotalInterest)
                $('#operatingLoanInterestCharge').text('$' + accounting.formatNumber(operatingLoanTotalInterest));
                
                // Set the adjusted operating loan net cost.
                operatingLoanNetCost = getSum(adjustedOperatingLoanPrincipal, operatingLoanTotalInterest);
                $('#operatingLoanNetCost').text('$' + accounting.formatNumber(operatingLoanNetCost));
                
                // Set the total net savings.
                totalNetSavings = getDifference(operatingLoanNetCost, financeProgramNetCost);
                $('#totalNetSavings').text('$' + accounting.formatNumber(totalNetSavings));
                
                if(totalAcres > 0){
                    savingsPerAcre = totalNetSavings/totalAcres;
                    $('#savingsPerAcre').text('$' + savingsPerAcre.toFixed(2));
                } else {
                    $('#savingsPerAcre').text('-');
                }
            }

            if (programExampleProductDiscount != false && parseInt(programExampleProductDiscount) != 0) {
                // // Manage Program Example Product Discount
                discountLabel = programExampleProductDiscount + '%';
                discountValue = programExampleProductDiscount;
                console.log('Program Example Product Discount = ' + discountLabel);


                $('#financeProgramDateDiscountLabel').text(discountLabel);
                
                // Set the finance program seed discount amount.
                financeProgramDateDiscount = parseFloat(discountValue / 100) * principal;
                $('#financeProgramDateDiscountAmount').text('$' + accounting.formatNumber(financeProgramDateDiscount));
                
                // Set the finance program seed discounted amount.
                financeProgramDateDiscountedAmount = parseFloat(principal - financeProgramDateDiscount);
                $('#financeProgramDateDiscountedAmount').text('$' + accounting.formatNumber(financeProgramDateDiscountedAmount));
                
                // Get the adjusted finance program principal.
                adjustedFinanceProgramPrincipal = getDifference(principal, financeProgramDateDiscount);
                
                // Set the adjusted finance program interest charge.
                financeProgramTotalInterest = Math.round((adjustedFinanceProgramPrincipal) * ((financeProgramInterestRate / 365) * financeProgramDaysOfInterest));
                console.log('financeProgramTotalInterest: ' + financeProgramTotalInterest)
                $('#financeProgramInterestCharge').text('$' + accounting.formatNumber(financeProgramTotalInterest));
                
                // Set the adjusted finance program net cost.
                financeProgramNetCost = getSum(adjustedFinanceProgramPrincipal, financeProgramTotalInterest);
                $('#financeProgramNetCost').text('$' + accounting.formatNumber(financeProgramNetCost));
                
                // Set the total net savings.
                totalNetSavings = getDifference(operatingLoanNetCost, financeProgramNetCost);
                $('#totalNetSavings').text('$' + accounting.formatNumber(totalNetSavings));
                
                if(totalAcres > 0){
                    savingsPerAcre = totalNetSavings/totalAcres;
                    $('#savingsPerAcre').text('$' + savingsPerAcre.toFixed(2));
                } else {
                    $('#savingsPerAcre').text('-');
                }
            }
        }
        var principal = accounting.unformat($('#purchaseAmount').val());
        console.log('principal: ' + principal);
        
        var adjustedPrincipal = 0;
        var adjustedFinanceProgramPrincipal = 0;
        var adjustedOperatingLoanPrincipal = 0;
        
        let programID = $('#financeProgramRate').val();
        var selectedProgram = window.financePrograms.reduce(function (selected, program) {
            return (program.id == programID) ? program : selected;
        }, false);
        
        var financeProgramDaysOfInterest = getEligibleDaysOfInterest(selectedProgram);
        console.log('Finance Program Eligible Days Of Interest is: ' + financeProgramDaysOfInterest)
        
        let prime = parseFloat($('#primeRate').val());
        let financeProgramInterestRate = parseFloat(selectedProgram.rate / 100)
        if (selectedProgram.rate_type == "p") {
            financeProgramInterestRate = parseFloat((prime + parseFloat(selectedProgram.rate)) / 100);
        }

        //   var financeProgramInterestRate = getInterestRate(selectedProgram);
        var financeProgramTotalInterest = 0;
        var financeProgramNetCost = 0;
        var financeProgramDateDiscount = 0;
        var totalAcres = 0;
        
        var operatingLoanDaysOfInterest = getEligibleDaysOfInterest(selectedProgram, true);
        console.log('Operating Loan Eligible Days Of Interest is: ' + operatingLoanDaysOfInterest)
        
        var operatingLoanInterestRate = getInterestRate($('#operatingLoanRate').val());
        var totalAcres = $('#totalAcres').val();
        
        var operatingLoanTotalInterest = 0;
        var operatingLoanNetCost = 0;
        var operatingLoanDateDiscount = 0;
        
        var productDiscount = 0;
        var cashDiscount = 0;
        var totalNetSavings = 0;
        
        // Set the purchase amount in the table.
        $('.gross-purchase-amount').text('$' + accounting.formatNumber(principal));
        
        // Update the finance program & operating loan UI.
        $('#selectedFinanceProgramRate').text(' (' + $('#financeProgramRate option:selected').text() + ')');
        
        $('#selectedOperatingLoanOrInHouse').empty().text(getLoanHeading());
        $('#selectedOperatingLoanRate').text(' (' + $('#operatingLoanRate option:selected').text() + ')');
        
        // Set the adjusted finance program interest charge.
        financeProgramTotalInterest = Math.round((principal) * ((financeProgramInterestRate / 365) * financeProgramDaysOfInterest));
        $('#financeProgramInterestCharge').text('$' + accounting.formatNumber(financeProgramTotalInterest));
        
        // Set the adjusted operating loan interest charge.
        operatingLoanTotalInterest = Math.round((principal) * ((operatingLoanInterestRate / 365) * operatingLoanDaysOfInterest));
        $('#operatingLoanInterestCharge').text('$' + accounting.formatNumber(operatingLoanTotalInterest));
        
        // Set the adjusted finance program net cost.
        financeProgramNetCost = getSum(principal, financeProgramTotalInterest);
        $('#financeProgramNetCost').text('$' + accounting.formatNumber(financeProgramNetCost));
        
        // Set the adjusted operating loan net cost.
        operatingLoanNetCost = getSum(principal, operatingLoanTotalInterest);
        $('#operatingLoanNetCost').text('$' + accounting.formatNumber(operatingLoanNetCost));
        
        // Set the total net savings.
        totalNetSavings = getDifference(operatingLoanNetCost, financeProgramNetCost);
        console.log('totalNetSavings: ' + totalNetSavings);
        $('#totalNetSavings').text('$' + accounting.formatNumber(totalNetSavings));
        if(totalAcres > 0){
            savingsPerAcre = totalNetSavings/totalAcres;
            $('#savingsPerAcre').text('$' + savingsPerAcre.toFixed(2));
        } else {
            $('#savingsPerAcre').text('-');
        }

        
        let olDiscountEnabled = false;
        let peDiscountEnabled = false;
        
        if ($("#operatingLoanProductDiscount").length > 0) {
            console.log('Operating Loan Product Discount Available');
            olDiscountEnabled = true;
            // Set the operating loan program seed discount amount.
            discount = parseInt($("#operatingLoanProductDiscount").val());
            processDiscounts(discount);
        } else {
            console.log('Operating Loan Product Discount Not Available');
        }

        if ($("#showDiscounts").length > 0 && $("#showDiscounts").is(":checked")) {
            olDiscountEnabled = true;
            peDiscountEnabled = true;
            console.log('Program Discount Available')
            let operatingLoanDiscount = $("#cashDiscount").val();
            let programExampleDiscount = $("#productDiscount").val();
            processDiscounts(operatingLoanDiscount, programExampleDiscount);
        } else {
            console.log('Program Discount Not Available');
        }
        
        let activeProgramDiscount = false;
        if (selectedProgram.discounts.length > 0) {
            let now = $('#purchaseDate').val();
            activeProgramDiscount = selectedProgram.discounts.reduce(function (active, discount) {
                console.log(discount.start, now, discount.end)
                if (treatAsUTC(discount.start) <= treatAsUTC(now) && treatAsUTC(now) <= treatAsUTC(discount.end))
                    return discount;
                
                return active;
            }, false)
        }
        
        if (activeProgramDiscount != false) {
            console.log('Has Date Discount');
            $("tr.discount-row").show();
            
            console.log(activeProgramDiscount);
            let old = parseInt(activeProgramDiscount.old)
            if (!isNaN(old) && old > 0) {
                processDiscounts(old, activeProgramDiscount.fpd);
            } else {
                processDiscounts(false, activeProgramDiscount.fpd);
            }
            
        } else {
            console.log('Empty Date Discount');
            if ($("#operatingLoanProductDiscount").length == 0 && ($("#showDiscounts").length == 0 || $("#showDiscounts").is(":checked") == false)) {
                $("tr.discount-row").hide();
            }
            if (peDiscountEnabled == false) {
                $("#financeProgramDateDiscountLabel, #financeProgramDateDiscountAmount, #financeProgramDateDiscountedAmount").show().text("N/A");
            }
            if (olDiscountEnabled == false) {
                $("#operatingLoanDateDiscountLabel, #operatingLoanDateDiscountAmount, #operatingLoanDateDiscountedAmount").show().text("N/A");
            }
        }
    }

    function getEligibleDaysOfInterest(program, skipInterestFree = false) {
        let millisecondsPerDay = 24 * 60 * 60 * 1000;
        
        let startDate = $('#purchaseDate').val();
        let interestFreeDays = parseInt(program.interest_free);
        let endDate, eligibleDaysOfInterest;
        
        
        // Find the end date
        if ($("#hide_payment_due_date").length == 1) { // end date is controled by the program
            endDate = program.due_date
        } else { // end date is controled by the user
            endDate = $('#paymentDueDateMonth').val() + '/' + statementCycleDays + '/' + $('#paymentDueDateYear').val();
        }
        
        eligibleDaysOfInterest = (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
        
        if (!skipInterestFree) {
            if (!isNaN(interestFreeDays) && interestFreeDays > 0) { // interest_free_days was added to the program
                console.log('Days Interest Free set to: ' + program.interest_free);
                if (program.interest_free.indexOf('/') != -1) { // Intrest Free is a date
                    console.log('Days Interest Free is a date at which point interest starts')
                    eligibleDaysOfInterest = (treatAsUTC(endDate) - treatAsUTC(program.interest_free)) / millisecondsPerDay;
                } else {
                    console.log('Days Interest Free is a number of days until interest starts')
                    eligibleDaysOfInterest -= interestFreeDays;
                }
            }
        }
        
        return eligibleDaysOfInterest < 0 ? 0 : eligibleDaysOfInterest;
    }
    
    var treatAsUTC = function (date) {
        var result = new Date(date);
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    };
    
    function getInterestRate(value) {
        //remove commentary
        value = value.replace(/[\r\n]+/gm, "");
        value = value.replace(/#.+$/,"");
        prime = $('#primeRate').val();
        
        //auto-duedate - duedate is auto-set based on program options (this happens on onchange, just remove)
        value = value.charAt(0) === 'a' ? value.substring(1) : value;
        
        // remove the due date from the value string. This is currently only used in the Southern States Calc
        value = value.charAt(0) === 'd' ? value.substring(11) : value;
        
        if (value.charAt(0) === 'f') {
            return parseFloat(value.substring(1) / 100);
        }
        
        if (value.charAt(0) === 'p') {
            return getSum(parseFloat(value.substring(1)), accounting.unformat(prime)) / 100;
        }
        
        if (value.charAt(0) === 'n' || value.indexOf('w') != -1) {
            return getSum(11.90, accounting.unformat(prime)) / 100;
        }
        
        // NPNI until plus Prime
        if (value.charAt(0) === 'u') {
            console.log([2, value.substring(23)]);
            return getSum(parseFloat(value.substring(23)), accounting.unformat(prime)) / 100;
        }
    }
    
    function getSum(x, y) {
        return (x + y);
    }
    
    function getDifference(x, y) {
        return (x - y);
    }
    
    function validatePaymentDate() {
        var purchaseDate = moment($('#purchaseDate').val()).format('MM/DD/YYYY');
        var purchaseMonth = purchaseDate.split('/')[0];
        var purchaseYear = purchaseDate.split('/')[2];
        var paymentMonth = $('#paymentDueDateMonth').val();
        var paymentYear = $('#paymentDueDateYear').val();
        
        if (purchaseDate) {
            console.log(purchaseDate);
            if (paymentYear) {
                if (purchaseYear <= paymentYear) {
                    console.info('--- YEAR IS VALID ----');
                    if (purchaseMonth < paymentMonth) {
                        console.info('--- MONTH IS VALID ---');
                        return true;
                    } else {
                        if (purchaseMonth == paymentMonth) {
                            console.info('--- MONTH IS VALID ---');
                            return true;
                        } else {
                            if ((purchaseMonth > paymentMonth) && (purchaseYear >= paymentYear)) {
                                console.info('--- ERROR: payment month is before purchase month ---');
                                return false;
                            } else {
                                console.info('--- MONTH IS VALID ---');
                                return true;
                            }
                        }
                    }
                } else {
                    console.info('--- ERROR: payment year is before purchase year ---');
                    return false;
                }
            } else {
                console.info('--- ERROR: choose a year ---');
                return false;
            }
        } else {
            console.info('--- ERROR: enter a purchase date ---');
            return false;
        }
    }
    
    function getLoanHeading() {
        var selectedOperatingLoan = $('#operatingLoanRate option:selected').val();
        switch (selectedOperatingLoan) {
            case 'f12.00':
            case 'f18.00':
            case 'f22.00':
            return 'In-House Account';
            case '':
            return '-';
            default:
            return 'Operating Loan';
        }
    }
});


document.getElementById('purchaseAmount').addEventListener('input', function(e) {
    let value = e.target.value.replace(/[^0-9]/g, '');
    if (value) {
        value = new Intl.NumberFormat('en-US').format(value);
        e.target.value = '$' + value;
    } else {
        e.target.value = '';
    }
});